.content {
  width: calc(100% - 65px) !important;
  margin-left: 65px !important;
  box-sizing: border-box;

  &.animated, .animated {
    width: 100%;
    box-sizing: border-box;
    animation: animated-text 1000ms;
  }

  .margin-bottom {
    margin-bottom: 10px;
  }

  .description {
    padding: 20px;
    text-align: justify;
  }

  .formation-box {
    padding: 20px;
    width: 260px;

    .MuiTypography-root {
      color: #34495e;
    }
  }

  .formation-box + .formation-box {
    margin-top: 20px;
  }

  .link {
    margin: 20px;
    padding: 8px;
    text-decoration: none;
    background: #3498db;
    cursor: pointer;
    &:hover {
      transition: all 500ms;
      text-shadow: 0 0 5px #2c3e50;
      box-shadow: 0 4px 5px 0 #2c3e50;
    }
  }

  .panel {
    box-sizing: border-box;
    padding: 20px;
    margin-top: 20px;

    &.full-width {
      width: 92%;
    }
  }

  .contact-button {
    + .contact-button {
      margin-left: 20px;
    }
  }

  .input-icon {
    fill: #2c3e50;
  }
}