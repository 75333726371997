.avatar {
  width: 250px !important;
  height: 250px !important;
  border: 8px solid #2c3e50;
  animation: scaleXY 3000ms;
  margin-bottom: 20px;

  > img {
    &:hover {
      transition: all 1000ms;
      transform: scale(1.1);
    }
  }
}

@keyframes scaleXY {
  from {
    transform: scale(0, 0);
  }

  to {
    transform: scale(1, 1);
  }
}