.text-area {
  width: 100%;
  height: auto;
  overflow: hidden;
  box-sizing: border-box;
  margin-bottom: 10px;
  font-size: .8em;

  > textarea {
    margin-top: 20px;
    padding: 10px;
    box-sizing: border-box;
    color: #2c3e50;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    outline: none;
    border: none;
    border-left: 1px solid rgba(0, 0, 0, 0.4);
    background: rgba(0, 0, 0, .1);
    transition: all ease-out 300ms;

    &:hover {
      border-left: 2px solid rgba(0, 0, 0, 0.87);
    }

    &:focus {
      border-left: 2px solid #3f51b5;
    }
  }

  &.with-error {
    > textarea {
      &:hover {
        border-color: #f44336;
      }
    }
  }

  > .title {
    //color: #2c3e50;

    &.focused {
      //color: #3f51b5;
    }
  }

  > .counter {
    width: 100%;
    text-align: right;
    color: #3f51b5;
    font-size: .7em;
  }

  
}