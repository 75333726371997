$primaryColor: #ecf0f1;
$secondaryColor: #3498db;

.logo {
  width: 290px;
  text-align: left;
  font-size: 3em;
  padding: 20px;
  overflow: hidden;

  .arrow-right {
    display: inline-block;
    overflow: hidden;
    color: $primaryColor;
    text-transform: uppercase;
  }

  .carlos {
    color: $primaryColor;
    text-transform: uppercase;
  }

  .hb {
    color: $secondaryColor;
    text-transform: uppercase;
  }

  .underline {
    display: inline-block;
    overflow: hidden;
    color: $primaryColor;
    text-transform: uppercase;
    -webkit-animation: blink-animation 700ms steps(5, start) infinite;
    animation: blink-animation 700ms steps(5, start) infinite;
  }

  .letters {
    display: inline-block;
    -webkit-animation: size-animate 2000ms;
    animation: size-animate 2000ms;
    overflow: hidden;
  }
}

@keyframes size-animate {
  0%, 12.4% {
    width: 0;
  }

  12.5%, 24.9% {
    width: 30px;
  }

  25%, 37.4% {
    width: 60px;
  }

  37.5%, 49.9% {
    width: 90px;
  }

  50%, 62.4% {
    width: 115px;
  }

  62.5%, 74.9% {
    width: 150px;
  }

  75%, 87.4% {
    width: 178px;
  }

  87.5%, 99.9% {
    width: 210px;
  }

  100% {
    width: 240px;
  }
}
