.animated-text {
  animation: animated-text 3000ms;
}

@keyframes animated-text {
  0%, 66.5% {
    transform: scale3d(0, 0, 0);
  }

  100% {
    transform: scale3d(1, 1, 1);
  }
}

@keyframes animated-icon {
  0% {
    transform: scale3d(1, 1, 1);
  }

  50% {
    transform: scale3d(1.4, 1.4, 1.4);
  }

  100% {
    transform: scale3d(1, 1, 1);
  }
}

@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}