.icon {
  display: inline-block;
  width: 34px;
  height: 34px;
  overflow: hidden;
  cursor: pointer;

  &:hover {
    animation: animated-icon 2000ms;
  }

  > img {
    width: 100%;
    height: auto;
  }
}