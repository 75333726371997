.menu {
  & .MuiPaper-root {
    border-right: darken(#2c3e50, 30%);
    background: #2c3e50;
  }

  & .menu--icon {
    fill: #ecf0f1;
    padding: 8px 0;
  }
}